
const firebaseConfig = {
    apiKey: "AIzaSyCOUre0gk3PSCq3ocElzRZ_G1Ib2Jrvuug",
  authDomain: "standartapartment.firebaseapp.com",
  projectId: "standartapartment",
  storageBucket: "standartapartment.appspot.com",
  messagingSenderId: "317508116809",
  appId: "1:317508116809:web:c5a6694a7a29a7e8b73a98"
};

export default firebaseConfig;