import React, { useEffect, useState } from "react";
import { Input, Button, message, Progress, Image } from "antd";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import firebaseConfig from "../FireBaseConfig";
firebase.initializeApp(firebaseConfig);
var db = firebase.firestore();
const storage = firebase.storage();
var collectionRef = db.collection("Intro");

const { TextArea } = Input;

function Intro() {

  const [docID, setDocID] = useState("");
  const [title, setTitle] = useState("");
  const [textInfo, setTextInfo] = useState("");
  const [youTubeUrl, setYoutubeUrl] = useState("");
  const [fileData, setFileData] = useState(null);
  const [progress, setProgress] = useState(0);
  const [fileUrl, setFileUrl] = useState("");
  const [filePath, setFilePath] = useState("");

  useEffect(() => {
    getData();
  }, []);

   const getData =() => {

    collectionRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let tempData = doc.data();
          setDocID(doc.id);
          setTitle(tempData.title);
          setTextInfo(tempData.text);
          setYoutubeUrl(tempData.youtubeurl);
          setFileUrl(tempData.fileUrl);
          setFilePath(tempData.filePath);
        });
      })
      .catch((error) => {
        message.error(error);
      });
  }


  const saveData = () => {
    const saveData = {
      title: title,
      text: textInfo,
      youtubeurl: youTubeUrl,
      filePath: filePath,
      fileUrl: fileUrl,
    };

    if (docID) {
      collectionRef
        .doc(docID)
        .update(saveData)
        .then(function () {
          message.success("Мэдээллийн амжилттай хадгалсан.");
          getRefresh();
        })
        .catch(function (error) {
          message.error(error);
        });
    } else {
      collectionRef
        .add(saveData)
        .then(function (docRef) {
          message.success("Мэдээллийн амжилттай хадгалсан.");
          getRefresh();
        })
        .catch(function (error) {
          message.error(error);
        });
    }
  };


  const getRefresh = () => {

      setProgress(0);
      setFileData(null);
      setFilePath("");
      setFileUrl("");
   
  };

  const handleChange = (e) => {
    if (e.target.files[0]) {
      setFileData(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    const uploadTask = storage.ref(`images/${fileData.name}`).put(fileData);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgress(progress);
      },
      (error) => {
        message.error(error);
      },
      () => {
        storage.ref('images').child(fileData.name).getDownloadURL().then((url) => {
          setFileUrl(url);
          setFilePath("images/" + fileData.name);
          message.success("Файл амжилттай хуулсан");
        });
      }
    );
  };

  return (
    <>
      <Input
        value={title}
        placeholder="Мэдээлэл оруулах"
        onChange={(e) => {
          setTitle(e.target.value);
        }}
      />
      <br />
      <br />
      <TextArea
        rows={8}
        value={textInfo}
        placeholder="Мэдээлэл оруулах"
        maxLength={2000}
        onChange={(e) => {
          setTextInfo(e.target.value);
        }}
      />
      <br />
      <br />
      <Input
        value={youTubeUrl}
        placeholder="Мэдээлэл оруулах"
        onChange={(e) => {
          setYoutubeUrl(e.target.value);
        }}
      />
      <br />
      <br />
      <input type="file" onChange={handleChange} />
      <Button type="primary" onClick={handleUpload}>
        Файл хуулах
      </Button>
      <br />
        <br/>
        <center><Image
    width={200}
    src={fileUrl}
  /></center>
      <br />
      <Progress percent={progress} />
      <br />
      <Button
        type="primary"
        style={{ float: "right" }}
        onClick={() => {
          saveData();
        }}
      >
        Хадгалах
      </Button>
    </>
  );
}

export default Intro;
