import React, { useEffect, useState } from "react";
import { message } from "antd";
import style from "./Info.module.css";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import firebaseConfig from "../FireBaseConfig";

firebase.initializeApp(firebaseConfig);
var db = firebase.firestore();
var collectionRef = db.collection("step4");

function Info() {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    let dataList = [];
    collectionRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let tempData = doc.data();
          dataList.push({
            id: doc.id,
            textname: tempData.text,
            title: tempData.title,
          });
        });

        setData(dataList);
      })
      .catch((error) => {
        message.error(error);
      });
  }

  return (
    <div className={style.mainInfo}>
      <div className={style.infoBox}>
        {data?.map((item) => {
          return (
            <>
              <p className={style.infoP}>
                {item.textname}
              </p>
              <hr />
            </>
          );
        })}
      </div>
      <div className={style.infoBox1}>
        <img className={style.infoImg} src="assets/images/cover.jfif" />
      </div>
    </div>
  );
}

export default Info;
