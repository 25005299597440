import React, { useEffect, useState } from "react";
import style from "./Document.module.css";
import firebaseConfig from "../FireBaseConfig";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore/lite";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function Documents() {
  const [data, setData] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const orderInfoCol = collection(db, "Files");
    const orderInfoSnapshot = await getDocs(orderInfoCol);
    const orderInfoList = orderInfoSnapshot.docs.map((doc) => doc.data());

    setData(orderInfoList);
  }

  return (
    <div className={style.mainInfoNumber}>
      {data.map((item) => {
        return (
          <div className={style.infoNumberBox} onClick={() => { window.open(item.fileUrl); }}>
            <div>
              <img
                className={style.bonusImg}
                src="assets/images/doc.png"
                width={80}
                height={80}
              />
            </div>
            <p
              style={{ fontSize: 25, fontWeight: "bold" }}
            >
              {item.fileName}
            </p>
            <span style={{ fontSize: 14, textAlign: "justify" }}>
              {item.fileInfo}
            </span>
          </div>
        );
      })}
    </div>
  );
}

export default Documents;
