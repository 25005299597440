import React, { useEffect, useState } from "react";
import { Input, Button, message  } from "antd";

import firebaseConfig from "../FireBaseConfig";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, setDoc, doc  } from "firebase/firestore";
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


const { TextArea } = Input;

function HomeText() {
  const [data, setData] = useState("");
  const [money, setMoney] = useState("");

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const homeTextCol = collection(db, "HomeText");
    const homeTextSnapshot = await getDocs(homeTextCol);
    const homeTextList = homeTextSnapshot.docs.map((doc) => doc.data());
    setData(homeTextList[0]?.text);
    setMoney(homeTextList[0]?.money);
  }

  const saveData = () => {
    
const frankDocRef = doc(db, "HomeText", "HomeTextDoc");
 setDoc(frankDocRef, {
    text: data,
    money: money
}).then(function() {
    message.success("Мэдээллийг амжилттай хадгалсан");
  });

  }

  return (
    <>
          <Input
        value={money}
        placeholder="Мэдээлэл оруулах"
        onChange={(e) => {setMoney( e.target.value)}}
      />
      <br />
      <br />
      <TextArea
        rows={8}
        value={data}
        placeholder="Мэдээлэл оруулах"
        maxLength={500}
        onChange={(e) => {setData( e.target.value)}}
      />
      <br />
      <br />
      <Button type="primary" style={{ float: "right" }} onClick={()=> { saveData();}}>
        Хадгалах
      </Button>
    </>
  );
}

export default HomeText;
