import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from "./Home";
import AdminPage from "./Admin/AdminPage";

function App() {
  return (
<Router>
    <Routes>
        <Route exact path="/" element={<Home />} />
        <Route  path="/admin" element={<AdminPage />} />
    </Routes>
</Router>
  );
}

export default App