import React, { useEffect, useState } from "react";
import { Input, Button, message, Table, Modal, Popconfirm, Progress } from "antd";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import firebaseConfig from "../FireBaseConfig";
firebase.initializeApp(firebaseConfig);
var db = firebase.firestore();
const storage = firebase.storage();
var collectionRef = db.collection("Files");

const columns = [
  {
    title: "Файлын нэр",
    dataIndex: "fileName",
    key: "fileName",
    render: (text) => <a href="#">{text}</a>,
  },
  {
    title: "Нэмэлт мэдээлэл",
    dataIndex: "fileInfo",
    key: "fileInfo",
  },
];

function Files() {

  const [data, setData] = useState([]);
  const [fileName, setFileName] = useState("");
  const [fileInfo, setFileInfo] = useState("");
  const [visible, setVisible] = useState(false);
  const [docID, setDocID] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [progress, setProgress] = useState(0);
  const [fileUrl, setFileUrl] = useState('');
  const [filePath, setFilePath] = useState('');

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    let dataList = [];
    collectionRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let tempData = doc.data();
          dataList.push({
            id: doc.id,
            fileName: tempData.fileName,
            fileInfo: tempData.fileInfo,
            filePath: tempData.filePath,
            fileUrl:  tempData.fileUrl,
          });
        });

        setData(dataList);
      })
      .catch((error) => {
        message.error(error);
      });
  }

  const saveData = () => {
    const saveData = {
      fileName: fileName,
      fileInfo: fileInfo,
      filePath: filePath,
      fileUrl: fileUrl,
    };

    if (isUpdate) {
      collectionRef
        .doc(docID)
        .update(saveData)
        .then(function () {
          message.success("Мэдээллийн амжилттай хадгалсан.");
          getRefresh();
        })
        .catch(function (error) {
          message.error(error);
        });
    } else {
      collectionRef
        .add(saveData)
        .then(function (docRef) {
          message.success("Мэдээллийн амжилттай хадгалсан.");
          getRefresh();
        })
        .catch(function (error) {
          message.error(error);
        });
    }
  };

  const handleOk = (e) => {
    saveData();
    setVisible(false);
    getData();
  };

  const tableOnRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        setFileName(record.fileName);
        setFileInfo(record.fileInfo);
        setDocID(record.id);
        setFilePath(record.filePath);
        setFileUrl(record.fileUrl);
        setFileData(null);
        setIsUpdate(true);
        setVisible(true);
      },
    };
  };

  const getRefresh = () => {
    setDocID("");
    setFileName("");
    setFileInfo("");
    setIsUpdate(false);
    setVisible(false);
    setFilePath('');
    setFileUrl('');
    setProgress(0);
    setFileData(null);
    getData();
  };

  const onDeleteRow = () => {
    if (isUpdate) {
      collectionRef
        .doc(docID)
        .delete()
        .then(function () {
          message.success("Мэдээллийн амжилттай хадгалсан.");
          deleteImage();
          getRefresh();
        })
        .catch(function (error) {
          message.success(error);
        });
    }
  };

  const deleteImage = () => {
    const storageRef = firebase.storage().ref();
    const imageRef = storageRef.child(filePath);
  
    // Delete the image
    imageRef.delete().then(() => {
      message.success('Файл устгасан');
    }).catch((error) => {
      message.error('Error deleting image:', error);
    });
  };

  const handleChange = (e) => {
    if (e.target.files[0]) {
      setFileData(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    const uploadTask = storage.ref(`images/${fileData.name}`).put(fileData);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgress(progress);
      },
      (error) => {
        message.error(error);
      },
      () => {
        storage.ref('images').child(fileData.name).getDownloadURL().then((url) => {
          setFileUrl(url);
          setFilePath("images/" + fileData.name);
          message.success("Файл амжилттай хуулсан");
        });
      }
    );
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          getRefresh();
          setVisible(true);
        }}
      >
        Шинээр нэмэх
      </Button>
      <br />
      <br />
      <Table
        columns={columns}
        dataSource={data}
        onRow={tableOnRow}
        size="small"
      />
      <Modal
        title="Шинээр нэмэх"
        visible={visible}
        onOk={handleOk}
        onCancel={() => {
          setVisible(false);
        }}
        footer={[
          <Popconfirm
            key={1}
            placement="top"
            title={"Та мэдээллийг устгахдаа итгэлтэй байна уу?"}
            okText="Тийм"
            cancelText="Үгүй"
            onConfirm={() => onDeleteRow()}
          >
            <Button type={"primary"} style={{ float: "left" }} danger  disabled = {isUpdate ? false : true}>
              Устгах
            </Button>
          </Popconfirm>,
          <Button key={2} onClick={() => handleOk()}>
            Хадгалах
          </Button>,
        ]}
      >
        <Input
          value={fileName}
          onChange={(value) => {
            setFileName(value.target.value);
          }}
          placeholder="Файлын нэр"
        />
        <br />
        <br />
        <Input
          value={fileInfo}
          onChange={(value) => {
            setFileInfo(value.target.value);
          }}
          placeholder="Нэмэлт мэдээлэл"
        />
   <br />
        <br />
<input type="file" onChange={handleChange} />
<Button type="primary" onClick={handleUpload}>Файл хуулах</Button>
      <br />
      <Progress percent={progress} />
      <center><Button type="link" onClick={()=> {window.open(fileUrl); }}>Файл үзэх</Button></center>
      <br />
      </Modal>
    </>
  );
}

export default Files;
