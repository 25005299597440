import React, { useEffect, useState } from "react";
import style from "./InfoNumber.module.css";
import firebaseConfig from "../FireBaseConfig";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore/lite";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function InfoNumber() {
  const [data, setData] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const orderInfoCol = collection(db, "OrderInfo");
    const orderInfoSnapshot = await getDocs(orderInfoCol);
    const orderInfoList = orderInfoSnapshot.docs.map((doc) => doc.data());
    
    setData(orderInfoList);
  }

  return (
    <div className={style.mainInfoNumber}>
      {data.map((item) => {
        return (
          <div className={style.infoNumberBox}>
            <p style={{ color: "white", fontSize: 50, fontWeight: "bold" }}>
              {item.totalnumber}
            </p>
            <br />
            <p style={{ color: "white", fontSize: 20 }}>{item.textname}</p>
          </div>
        );
      })}
    </div>
  );
}

export default InfoNumber;
