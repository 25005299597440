import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  message,
  Table,
  Modal,
  Popconfirm,
  Progress,
  Image
} from "antd";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import firebaseConfig from "../FireBaseConfig";

firebase.initializeApp(firebaseConfig);
var db = firebase.firestore();
const storage = firebase.storage();
var collectionRef = db.collection("BonusSlide");

const columns = [
  {
    title: "Файлийн нэр",
    dataIndex: "fileTitle",
    key: "fileTitle",
    render: (text) => <a href="#">{text}</a>,
  },
  {
    title: "Мэдээлэл",
    dataIndex: "textname",
    key: "textname",
  },
];

function BonusSlide() {
  const [data, setData] = useState([]);
  const [fileTitle, setFileTitle] = useState("");
  const [visible, setVisible] = useState(false);
  const [docID, setDocID] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [progress, setProgress] = useState(0);
  const [fileUrl, setFileUrl] = useState("");
  const [filePath, setFilePath] = useState("");
  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    let dataList = [];
    collectionRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let tempData = doc.data();
          dataList.push({
            id: doc.id,
            fileTitle: tempData.fileTitle,
            fileUrl: tempData.fileUrl,
            filePath: tempData.filePath,
          });
        });

        setData(dataList);
      })
      .catch((error) => {
        message.error(error);
      });
  }

  const saveData = async () => {
    let saveData = {
      fileTitle: fileTitle,
      fileUrl: fileUrl,
      filePath: filePath,
    };

    if (isUpdate) {
      await collectionRef
        .doc(docID)
        .update(saveData)
        .then(function () {
          message.success("Мэдээллийн амжилттай хадгалсан.");
          getRefresh();
        })
        .catch(function (error) {
          message.error(error);
        });
    } else {
      await collectionRef
        .add(saveData)
        .then(function (docRef) {
          message.success("Мэдээллийн амжилттай хадгалсан.");
          getRefresh();
        })
        .catch(function (error) {
          message.error(error);
        });
    }
  };

  const handleOk = (e) => {
    saveData();
    setVisible(false);
    getData();
  };

  const tableOnRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        setFileTitle(record.fileTitle);
        setDocID(record.id);
        setFileUrl(record.fileUrl);
        setFilePath(record.filePath);
        setFileData(null);
        setIsUpdate(true);
        setVisible(true);
        setProgress(0);
      },
    };
  };

  const getRefresh = () => {
    setDocID("");
    setFileTitle("");
    setIsUpdate(false);
    setVisible(false);
    setFileUrl("");
    setFilePath("");
    setFileData(null);
    setProgress(0);
    getData();
  };

  const onDeleteRow = () => {
    if (isUpdate) {
      collectionRef
        .doc(docID)
        .delete()
        .then(function () {
          message.success("Мэдээллийн амжилттай устгасан.");
          deleteImage();
          getRefresh();
        })
        .catch(function (error) {
          message.error(error);
        });
    }
  };

  const saveFileAndData = async () => {
    await saveData();
  };

  const deleteImage = () => {
    const storageRef = firebase.storage().ref();
    const imageRef = storageRef.child(filePath);

    // Delete the image
    imageRef
      .delete()
      .then(() => {
        message.success("Файл устгасан");
      })
      .catch((error) => {
        message.error("Error deleting image:", error);
      });
  };

  const handleChange = (e) => {
    if (e.target.files[0]) {
      setFileData(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    const uploadTask = storage.ref(`images/${fileData.name}`).put(fileData);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      (error) => {
        message.error(error);
      },
      () => {
        storage
          .ref("images")
          .child(fileData.name)
          .getDownloadURL()
          .then((url) => {
            setFileUrl(url);
            setFilePath("images/" + fileData.name);
            message.success("Файл амжилттай хуулсан");
          });
      }
    );
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          getRefresh();
          setVisible(true);
        }}
      >
        Шинээр нэмэх
      </Button>
      <br />
      <br />
      <Table
        columns={columns}
        dataSource={data}
        onRow={tableOnRow}
        size="small"
      />
      <Modal
        title="Шинээр нэмэх"
        visible={visible}
        onOk={handleOk}
        onCancel={() => {
          setVisible(false);
        }}
        footer={[
          <Popconfirm
            key={1}
            placement="top"
            title={"Та мэдээллийг устгахдаа итгэлтэй байна уу?"}
            okText="Тийм"
            cancelText="Үгүй"
            onConfirm={() => onDeleteRow()}
          >
            <Button
              type={"primary"}
              style={{ float: "left" }}
              danger
              disabled={isUpdate ? false : true}
            >
              Устгах
            </Button>
          </Popconfirm>,
          <Button key={2} onClick={saveFileAndData}>
            Хадгалах
          </Button>,
        ]}
      >
        <Input
          value={fileTitle}
          onChange={(value) => {
            setFileTitle(value.target.value);
          }}
          placeholder="Гарчиг"
        />
        <br />
        <br />
        <input type="file" onChange={handleChange} />
        <Button type="primary" onClick={handleUpload}>
          Файл хуулах
        </Button>
        <br />
        <br/>
        <center><Image
    width={200}
    src={fileUrl}
  /></center>
        <Progress percent={progress} />
        <br />
      </Modal>
    </>
  );
}

export default BonusSlide;
