import React, { useState } from "react";
import HomeText from "./HomeText";
import OrderInfo from "./OrderInfo";
import Intro from "./Intro";
import Step4 from "./Step4";
import BonusAdmin from "./BonusAdmin";
import Files from "./Files";
import BonusSlide from "./BonusSlide";
import { DesktopOutlined, FileOutlined, EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, theme, Input, Button, message } from "antd";
const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem("Нүүр хуудас", "1", <DesktopOutlined />),
  getItem("Захиалга мэдээлэл", "2", <DesktopOutlined />),
  getItem("Танилцуулга", "3", <DesktopOutlined />),
  getItem("Танилцуулга /Төсөл/", "4", <FileOutlined />),
  getItem("Урамшуулал", "5", <FileOutlined />),
  getItem("Урамшуулал зураг", "6", <FileOutlined />),
  getItem("Файл", "7", <FileOutlined />),
];

const AdminPage = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [page, setPage] = useState(<HomeText />);
  const [isLogin, setIsLogin] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const onClickMenu = (index) => {
    if (index === "1") setPage(<HomeText />);
    else if (index === "2") setPage(<OrderInfo />);
    else if (index === "3") setPage(<Intro />);
    else if (index === "4") setPage(<Step4 />);
    else if (index === "5") setPage(<BonusAdmin />);
    else if (index === "6") setPage(<BonusSlide />);
    else if (index === "7") setPage(<Files />);
  };

  const getLogin = () => {

    if(userName === "admin" && password === "admin")
    {
      setIsLogin(true);
    }
    else
    {
      message.error("Хэрэглэгчийн нэвтрэх нэр нууц үг буруу байна.");
    }

  }

  return (
    <>
      {isLogin ? (
        <Layout
          style={{
            minHeight: "100vh",
          }}
        >
          <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
          >
            <div className="demo-logo-vertical" />
            <Menu
              theme="dark"
              defaultSelectedKeys={["1"]}
              mode="inline"
              items={items}
              onClick={(index) => {
                onClickMenu(index.key);
              }}
            />
          </Sider>
          <Layout>
            <Header
              style={{
                padding: 0,
                background: colorBgContainer,
              }}
            />
            <Content
              style={{
                margin: "0 16px",
              }}
            >
              <Breadcrumb
                style={{
                  margin: "16px 0",
                }}
              >
                <Breadcrumb.Item>Админ хуудас</Breadcrumb.Item>
              </Breadcrumb>
              <div
                style={{
                  padding: 24,
                  minHeight: 360,
                  background: colorBgContainer,
                }}
              >
                {page}
              </div>
            </Content>
            <Footer
              style={{
                textAlign: "center",
              }}
            >
              ©2023 Power by Amaraa
              <div style={{ color: "white", fontSize: 1 }}>
                <span id="days"></span>
                <span id="hours"></span>
                <span id="minutes"></span>
                <span id="seconds"></span>
              </div>
            </Footer>
          </Layout>
        </Layout>
      ) : (
        <>
         <div style={{backgroundColor:"#f2f2f2", position:"absolute", padding:50 ,height:"100%", width:"100%"}}>
         <div style={{ padding: 50, borderRadius:20, width:600, backgroundColor:"white", position:"absolute", top:"50%", left:"50%", transform:"translate(-50%, -50%)", boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}>
         <center><img src="assets/images/login.avif" width={500}/></center>
              <Input placeholder="Нэвтрэх нэр" onChange={(value)=> { setUserName(value.target.value)}} /><br/><br/>
              <Input.Password placeholder="Нууц үг" onChange={(value)=> { setPassword(value.target.value)}}  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}/><br/><br/>
              <center><Button  type="primary" onClick={getLogin}>Нэвтрэх</Button></center>
            </div>
         
         </div>
      
          <div style={{ color: "white", fontSize: 1 }}>
            <span id="days"></span>
            <span id="hours"></span>
            <span id="minutes"></span>
            <span id="seconds"></span>
          </div>
        </>
      )}
    </>
  );
};
export default AdminPage;
