import React, { useEffect, useState } from "react";
import Documents from "./components/Documents";
import {  GoogleMap,
  InfoWindowF,
  MarkerF,
  useLoadScript } from '@react-google-maps/api';
import { Avatar } from "antd";
import Bonus from "./components/Bonus";
import Info from "./components/Info";
import InfoNumber from "./components/InfoNumber";
import HeaderText from "./components/HeaderText";
import imgss from "./logo.svg"
import {
  HomeOutlined
} from '@ant-design/icons';


import firebaseConfig from "./FireBaseConfig";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs} from "firebase/firestore/lite";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


const markers = [
  {
    id: 1,
    name: "Сүхбаатарын талбай",
    position: { lat: 47.9200155, lng: 106.9173840 },
    imgUrl:"https://firebasestorage.googleapis.com/v0/b/standartapartment.appspot.com/o/map1.png?alt=media&token=820718d1-4647-4133-9b24-34b28651f2ec"
  },
  {
    id: 2,
    name: "Сансар И-Март",
    position: { lat: 47.9230754, lng: 106.9338101 },
    imgUrl:"https://firebasestorage.googleapis.com/v0/b/standartapartment.appspot.com/o/map2.png?alt=media&token=d2d4c661-8a4b-4f3b-82e6-b7bc2fe1f2b7"
  },
  {
    id: 3,
    name: "Standart Apartment",
    position: { lat: 47.92950, lng: 106.9398542 },
    imgUrl:"https://firebasestorage.googleapis.com/v0/b/standartapartment.appspot.com/o/map3.png?alt=media&token=5eb5b085-800a-4134-9894-5ce7bfb57dba"
  }
];

function Home() {

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyC6MU6jgHLdlsPiFSBCHn0WbZN7WDoYjdo",
  });
  const [activeMarker, setActiveMarker] = useState(null);
  const [data, setData] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  async function getData() {

    const introCol = collection(db, "Intro");
    const introSnapshot = await getDocs(introCol);
    const introList = introSnapshot.docs.map((doc) => doc.data());
    setData(introList);
  }

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  return (
    <>
      <header class="header-area header-sticky">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <nav class="main-nav">
                <a class="logo">
                  <em>U.S.Standart apartment</em>
                </a>
              </nav>
            </div>
          </div>
        </div>
      </header>

      <div class="main-banner">
        <div class="counter-content">
          <ul>
            <li>
              Өдөр<span id="days"></span>
            </li>
            <li>
              Цаг<span id="hours"></span>
            </li>
            <li>
              Минут<span id="minutes"></span>
            </li>
            <li>
              Секунт<span id="seconds"></span>
            </li>
          </ul>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="main-content">
           <HeaderText/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <InfoNumber/>
      <div class="amazing-venues">
        <div class="container">
          <div class="row">
            <div class="col-lg-7">
              <div class="left-content">
                <Avatar
                  size={170}
                  style={{ float: "left", marginRight: 20 }}
                  src={data[0]?.fileUrl}
                />
                <center>
                  <h5>{data[0]?.title}</h5>
                </center>
                <p style={{ textAlign: "justify" }}>
             {data[0]?.text}
                </p>
              </div>
            </div>
            <div class="col-lg-5">
              <div class="right-content">
                <iframe
                  width="100%"
                  height="315"
                  src={data[0]?.youtubeurl}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                  autoplay
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <center><p style={{ fontSize:20 , color:"#f15b26"}}>Оруулсан хөрөнгө оруулалтаа 20% -н ашигтайнаар 100% буцаан авах боломжтой</p></center><br/>
      <Info />
      <br />
      <Bonus />
      <br />
      <Documents />
      {isLoaded ? (
            <GoogleMap
              center={{ lat: 47.9264041, lng: 106.9426763 }}
              zoom={14}
              onClick={() => setActiveMarker(null)}
              mapContainerStyle={{ width: "100%", height: "50vh" }}
            >
              {markers.map(({ id, name, position, imgUrl }) => (
                <MarkerF
                  key={id}
                  position={position}
                  onClick={() => handleActiveMarker(id)}
                   icon={{
                     url:imgUrl,
                     scaledSize: { width: 100, height: 80 }
                   }}
                >
                  {activeMarker === id ? (
                    <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
                      <div>
                        <p>{name}</p>
                      </div>
                    </InfoWindowF>
                  ) : null}
                </MarkerF>
              ))}
            </GoogleMap>
          ) : null}
      <footer>
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="sub-footer">
                <div class="row">
                  <div class="col-lg-3">
                    <p>© 2024 U.S.Standart apartment</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Home;
