import React, { useEffect, useState } from "react";
import { Input, Button, message, Table, Modal, Popconfirm, Space } from "antd";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import firebaseConfig from "../FireBaseConfig";
firebase.initializeApp(firebaseConfig);
var db = firebase.firestore();
var collectionRef = db.collection("OrderInfo");

const columns = [
  {
    title: "Техт",
    dataIndex: "textname",
    key: "textname",
    render: (text) => <a href="#">{text}</a>,
  },
  {
    title: "Тоо",
    dataIndex: "totalnumber",
    key: "totalnumber",
  },
];

function OrderInfo() {
  const [data, setData] = useState([]);
  const [textName, setTextName] = useState("");
  const [totalNumber, setTotalNumber] = useState("");
  const [visible, setVisible] = useState(false);
  const [docID, setDocID] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    let dataList = [];
    collectionRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let tempData = doc.data();
          dataList.push({
            id: doc.id,
            textname: tempData.textname,
            totalnumber: tempData.totalnumber,
          });
        });

        setData(dataList);
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
      });
  }

  const saveData = () => {
    const saveData = {
      textname: textName,
      totalnumber: totalNumber,
    };

    if (isUpdate) {
      collectionRef
        .doc(docID)
        .update(saveData)
        .then(function () {
          message.success("Мэдээллийн амжилттай хадгалсан.");
          getRefresh();
        })
        .catch(function (error) {
          message.error(error);
        });
    } else {
      collectionRef
        .add(saveData)
        .then(function (docRef) {
          message.success("Мэдээллийн амжилттай хадгалсан.");
          getRefresh();
        })
        .catch(function (error) {
          message.error(error);
        });
    }
  };

  const handleOk = (e) => {
    saveData();
    setVisible(false);
    getData();
  };

  const tableOnRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        setTextName(record.textname);
        setTotalNumber(record.totalnumber);
        setDocID(record.id);
        setIsUpdate(true);
        setVisible(true);
      },
    };
  };

  const getRefresh = () => {
    setDocID("");
    setTextName("");
    setTotalNumber("");
    setIsUpdate(false);
    setVisible(false);
    getData();
  };

  const onDeleteRow = () => {
    if (isUpdate) {
      collectionRef
        .doc(docID)
        .delete()
        .then(function () {
          message.success("Мэдээллийн амжилттай хадгалсан.");
          getRefresh();
        })
        .catch(function (error) {
          message.success(error);
        });
    }
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          getRefresh();
          setVisible(true);
        }}
      >
        Шинээр нэмэх
      </Button>
      <br />
      <br />
      <Table
        columns={columns}
        dataSource={data}
        onRow={tableOnRow}
        size="small"
      />
      <Modal
        title="Шинээр нэмэх"
        visible={visible}
        onOk={handleOk}
        onCancel={() => {
          setVisible(false);
        }}
        footer={[
          <Popconfirm
            key={1}
            placement="top"
            title={"Та мэдээллийг устгахдаа итгэлтэй байна уу?"}
            okText="Тийм"
            cancelText="Үгүй"
            onConfirm={() => onDeleteRow()}
          >
            <Button type={"primary"} style={{ float: "left" }} danger  disabled = {isUpdate ? false : true}>
              Устгах
            </Button>
          </Popconfirm>,
          <Button key={2} onClick={() => handleOk()}>
            Хадгалах
          </Button>,
        ]}
      >
        <Input
          value={textName}
          onChange={(value) => {
            setTextName(value.target.value);
          }}
          placeholder="Нэр"
        />
        <br />
        <br />
        <Input
          value={totalNumber}
          onChange={(value) => {
            setTotalNumber(value.target.value);
          }}
          placeholder="Тоо"
        />
      </Modal>
    </>
  );
}

export default OrderInfo;
