import React, { useEffect, useState } from "react";
import style from "./HeaderText.module.css";
import firebaseConfig from "../FireBaseConfig";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs} from "firebase/firestore/lite";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function HeaderText() {

  const [data, setData] = useState("");
  const [money , setMoney] = useState("");

  useEffect(() => {
    getData();

  }, []);

  async function getData() {
    const homeTextCol = collection(db, "HomeText");
    const homeTextSnapshot = await getDocs(homeTextCol);
    const homeTextList = homeTextSnapshot.docs.map((doc) => doc.data());
    setData(homeTextList[0]?.text);
    setMoney(homeTextList[0]?.money);
  }
  return (
    <div style={{ padding: 30, backgroundColor:"#848190", borderRadius: 20, opacity: 0.9}}>
    <span className={style.headerTextMoney}>{money}</span> 
  <p className={style.headerText}>{data}</p>
</div>
  );
}

export default HeaderText;
