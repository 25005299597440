import React, { useEffect, useState } from "react";
import style from "./Bonus.module.css";
import { Carousel, message } from "antd";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import firebaseConfig from "../FireBaseConfig";

firebase.initializeApp(firebaseConfig);
var db = firebase.firestore();
var collectionRef = db.collection("BonusInfo");
var collectionRef1 = db.collection("BonusSlide");

function Bonus() {
  const [data, setData] = useState([]);
  const [title, setTitle] = useState("");
  const [slideData, setSlideData] = useState([]);

  useEffect(() => {
    getData();
    getSlideData();
  }, []);

  async function getData() {
    let dataList = [];
    collectionRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let tempData = doc.data();
          dataList.push({
            id: doc.id,
            text: tempData.text,
            title: tempData.title,
            fileUrl: tempData.fileUrl,
          });
        });
        if (dataList.length > 0) {
          setTitle(dataList[0]?.title);
        }
        setData(dataList);
      })
      .catch((error) => {
        message.error(error);
      });
  }

  async function getSlideData() {
    let dataList = [];
    collectionRef1
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let tempData = doc.data();
          dataList.push({
            id: doc.id,
            fileUrl: tempData.fileUrl,
          });
        });
        setSlideData(dataList);
      })
      .catch((error) => {
        message.error(error);
      });
  }
  
  return (
    <center>
      <div class={style.mainBonus}>
        <div className={style.bonusBox1}>
          <Carousel autoplay>
            {slideData?.map((item) => {
              return (
                <div>
                  <img className={style.bonusImg} src={item.fileUrl} />
                </div>
              );
            })}
          </Carousel>
        </div>
        <div className={style.bonusBox}>
          <h2>{title}</h2>
          <br />

          <p style={{ color: "white" }}></p>

          <div className={style.mainBonusItem}>
            {data?.map((item) => {
              return (
                <div className={style.bonusItem}>
                  <img width={50} height={50} src={item.fileUrl} style={{marginLeft:20}} />
                  <p style={{ color: "white", padding: 10, fontSize: 18 }}>
                    {item.text}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </center>
  );
}

export default Bonus;
